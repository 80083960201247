import { ParagraphTestimonialFragmentFragment } from '../../../generated/types';
import React from 'react';
import HotelTestimonial from '../../Node/Hotel/HotelTestimonial';

interface TestimonialProps {
  data: ParagraphTestimonialFragmentFragment;
}

const Testimonial: React.FC<TestimonialProps> = ({ data }) => {
  return <HotelTestimonial image={data.image} rating={data.data} />;
};

export default Testimonial;
