import { Box, Flex } from '@chakra-ui/react';
import { CustomStarEmpty, CustomStarFilled } from '../../../config/icons';

interface RatingStarsProps {
  title?: string;
  value: number;
  hideValue?: Boolean;
}

export default function RatingStars({
  title,
  value,
  hideValue,
}: RatingStarsProps) {
  const roundedValue = Math.round(value);
  const emptyStars = 5 - roundedValue;

  return (
    <Flex flex={1} alignItems="center">
      {title && (
        <Box paddingRight={4} width={150}>
          {title}
        </Box>
      )}
      <Flex flex={1} alignItems="center" py={1}>
        <Flex fontSize="2xl" alignItems="center">
          {[...Array(roundedValue)].map((item, i) => (
            <CustomStarFilled key={i} fill="textColor" />
          ))}
          {[...Array(emptyStars)].map((item, i) => (
            <CustomStarEmpty key={i} fill="textColor" />
          ))}
        </Flex>
        {!hideValue && (
          <Box fontWeight="bold" paddingLeft={4}>
            {value.toFixed(1)}
          </Box>
        )}
      </Flex>
    </Flex>
  );
}
