import { Box, Button, Flex } from '@chakra-ui/react';
import { RatingFragmentFragment } from '../../../generated/types';
import React from 'react';
import Link from 'next/link';
import { HTMLText } from '../../Common/HTMLText';
import parseDateRange from '../../../utils/parseDateRange';
import { ImageWithDerivate } from '../../../types/global-types';
import RatingStars from './RatingStars';
import { Link as ScrollLink } from 'react-scroll';
import ImageEyeCatcherBase from '../../Common/ImageEyeCatcherBase';

interface HotelTestimonialProps {
  image: ImageWithDerivate<'big'>;
  rating: RatingFragmentFragment;
  darken?: Boolean;
  showCallToAction?: Boolean;
}

const HotelTestimonial: React.FC<HotelTestimonialProps> = ({
  image,
  rating,
  darken,
  showCallToAction,
}) => {
  if (!image || !rating) return null;

  const averageRating =
    (rating.ratingCatering + rating.ratingHousing + rating.ratingKindness) / 3;

  return (
    <ImageEyeCatcherBase image={image} darken={darken}>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Box
          color="textColor"
          fontSize={{
            base: 'md',
            lg: '2xl',
          }}
        >
          {rating.publicName
            ? rating.publicName
            : `${rating.firstname} ${rating.lastname}`}
          {rating.dateRange.value && rating.dateRange.end_value
            ? `, ${parseDateRange(
                rating.dateRange.value,
                rating.dateRange.end_value
              )}`
            : null}
        </Box>
        <Box
          fontSize={{
            base: 'md',
            lg: 'xl',
          }}
          color="#fff"
          marginTop={3}
          textAlign="center"
        >
          <HTMLText text={rating.body} />
        </Box>
        <Box>
          <RatingStars value={averageRating} hideValue />
        </Box>
        <Box marginTop={4} color="#fff">
          Bewertung für{' '}
          <Link href={rating.hotel.url} passHref>
            <Box as="a" color="textColor">
              {rating.hotel.title}
            </Box>
          </Link>
        </Box>
        {showCallToAction && (
          <Box mt={4}>
            <ScrollLink to="bewertungen" smooth>
              <Button color="#fff">Weitere Bewertungen</Button>
            </ScrollLink>
          </Box>
        )}
      </Flex>
    </ImageEyeCatcherBase>
  );
};

export default HotelTestimonial;
