import { Box, Flex, FlexProps } from '@chakra-ui/react';
import Container from '../Layout/Container';
import Wrapper from '../Paragraph/Wrapper';
import React from 'react';
import CopyrightImage from './CopyrightImage';
import Waves from './Waves';
import { ImageWithDerivate } from '../../types/global-types';

type ImageEyeCatcherBaseProps = FlexProps & {
  image: ImageWithDerivate<'big'>;
  darken?: Boolean;
};

const ImageEyeCatcherBase: React.FC<ImageEyeCatcherBaseProps> = ({
  image,
  children,
  darken,
  ...props
}) => {
  return (
    <Wrapper className="ImageEyeCatcherBase">
      <Flex
        alignItems="center"
        justifyContent="center"
        pos="relative"
        height={{
          base: '500px',
          md: '800px',
        }}
        overflow="hidden"
        {...props}
      >
        <CopyrightImage
          copyright={image?.copyright || ''}
          alt={image?.imageAlt || ''}
          title={image?.imageTitle || ''}
          pos="absolute"
          top="0"
          right="0"
          bottom="0"
          left="0"
          bg="#000"
          maxW="none"
          width="100%"
          height={{
            base: '500px',
            md: '800px',
          }}
          objectFit="cover"
          zIndex={1}
          src={image?.big?.url}
          lazy
        />
        {darken && (
          <Box
            pos="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg="rgba(0,0,0, .6)"
            zIndex={1}
          />
        )}
        <Box pos="relative" zIndex={3} width="100%">
          <Container>{children}</Container>
        </Box>
        <Waves flipped bottom="auto" top={0} zIndex={2} />
        <Waves zIndex={2} />
      </Flex>
    </Wrapper>
  );
};

export default ImageEyeCatcherBase;
